// *** _custom.scss ***
.mi-header-copyright {
  // height: 38px;
}

.bio-text {
  color: white;
  font-size: 20px;
  // line-height: 30px;
}

.center-children {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-children-vertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.height-100 {
  height: 100%;
}

.slick-arrow {
  margin-top: -40px !important;
  font-size: 30px !important;
  z-index: 99999;
}

.slick-slider {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.slick-list.draggable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mi-testimonial-slideritem {
  padding: 8px;
  color: white;
}

.mi-testimonial-small-text p {
  font-size: 18px !important;
  // line-height: 24px !important;
}

.mi-sectiontitle-small-margin {
  margin-bottom: 30px !important;
}

.white-text {
  color: #ffffff;
}

.bold {
  font-weight: bold;
}

.mi-contact-info {
  padding-top: 68px;
}

.large-description {
  font-size: 20px !important;
}

.slider-container-padding {
  padding-left: 40px;
  padding-right: 40px;
}

.page-padding {
  padding-left: 28px;
  padding-right: 28px;
}

.slight-bold {
  font-weight: 600;
}

.description {
  color: white;
}

.reddit-card-container {
  background-color: white;
  border-radius: 6px;
}

.size-20-text {
  font-size: 20px;
}

.horizontal-container {
  flex-direction: row;
  flex: 1;
}

.vertical-container {
  flex-direction: column;
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.horizontal-scrollable {
  overflow-x: scroll;
  border: 3px solid white;
}

.disabled-btn {
  pointer-events: none;
  background-color: gray !important;
}

// *** Portfolio Item Specific ***
.technology-labels {
  margin: 0px 0 0px 0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 14px;
  // background-color: rgba(255, 255, 255, 0.7);
  color: #007bff; // black;
  display: inline-block;
  //padding: 0px 8px;
  border-radius: 2px;
  font-weight: bold;
}


.image-right-margin {
  margin-right: 12px;
}

.precision-lifts-screens {
  display: inline-block;
}

.max-width-300 {
  max-width: 300px;
}

.max-height-400 {
  max-height: 480px;
}

.text-wrap-left {
  // display: inline-block;
  float: left;
  padding-right: 36px;
}

.text-wrap-right {
  // display: inline-block;
  float: right;
  padding-left: 36px;
}

.lead-image {
  // max-height: 500px;
  max-width: 1000px;
}